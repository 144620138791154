import { Col, Card, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useState, useEffect } from "react";

const CauraPromoTiles = () => {
  const [qrLink, setQrLink] = useState(window?.envConfig?.cauraUrl);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent) {
      if (userAgent.includes("android")) {
        setQrLink(window?.envConfig?.gcauraUrl);
      } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
        setQrLink(window?.envConfig?.icauraUrl);
      }
    }
  }, []);

  return (
    <>
      <Card>
        <div>
          <Row>
            <Col md={4} sm={12} className="d-none d-md-block m-auto">
              <div className="text-center">
                <img
                  src="/images/caura/cauratiles.png"
                  title="Caura"
                  alt="Caura"
                  fluid
                  width="80%"
                  max-width="100%"
                  height="auto"
                ></img>
              </div>
            </Col>
            <Col md={8} sm={12} className="text-center  p-2">
              <Row>
                <div className="my-md-3 ">
                  {" "}
                  <h2 className="text-primary text-center d-block d-none d-md-block">
                    <strong>Download for free</strong>
                  </h2>
                  <h3 className="text-primary text-center  d-block d-md-none d-sm-block">
                    <strong>Download for free</strong>
                  </h3>
                </div>
              </Row>
              <Row>
                <Col className="text-center p-2 d-none d-md-block ">
                  {qrLink && (
                    <div>
                      <QRCode
                        value={qrLink}
                        size={128}
                        title="Download Caura App"
                        viewBox={`0 0 128 128`}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="p-2">
                  <div className="txt-center mx-3">
                    <p className=" d-none d-md-block">
                      Scan this QR code to download Caura on your phone
                    </p>
                  </div>
                  <div className=" text-center d-md-none d-sm-block ">
                    <a href={qrLink} target="_blank" rel="noopener noreferrer">
                      {" "}
                      <img
                        src="/images/caura/appstore.png"
                        title="Caura App"
                        alt="Caura App"
                        fluid
                        width="50%"
                      ></img>
                    </a>
                    <p className=" d-md-none d-sm-block">
                      Download Caura on your phone
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default CauraPromoTiles;
